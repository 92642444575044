import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import {
  Autocomplete,
  Divider,
  IconButton,
  InputAdornment,
  Stack,
  TextField,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { setTemplate } from '../../../helpers/backend_helper';
import { toast } from 'react-toastify';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxWidth: '500px',
  width: '90%',
  borderRadius: '20px',
  bgcolor: 'background.paper',
  border: '2px solid transparent',
  boxShadow: 24,
  display: 'flex',
  flexDirection: 'column',
};

const PaymentLinkModal = ({ handleNewTemplate, isOpen }) => {
  
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);
  const formattedTomorrow = tomorrow.toISOString().split('T')[0];
  const twelveMonthsFromNow = new Date(today);
  twelveMonthsFromNow.setFullYear(twelveMonthsFromNow.getFullYear() + 1);
  const formattedTwelveMonthsFromNow = twelveMonthsFromNow
    .toISOString()
    .split('T')[0];
  // const endOfMonth = new Date(
  //   new Date().getFullYear(),
  //   new Date().getMonth() + 1,
  //   0
  // )
  //   .toISOString()
  //   .split('T')[0];
  const [templateData, setTemplateData] = useState({
    name: '',
    nameError: null,
    amount: '',
    amountError: null,
    instantDebitAmount: '',
    frequency: '',
    frequencyError: null,
    debitDate: '',
    debitDateError: null,
    paymentCount: '',
    paymentCountError: null,
  });

  const [maxPaymentCount, setMaxPaymentCount] = useState(12);

  useEffect(() => {
    if (templateData.frequency === 'Weekly') {
      setMaxPaymentCount(52);
    } else if (templateData.frequency === 'Monthly') {
      setMaxPaymentCount(12);
    }
  }, [templateData.frequency]);

  const handleChange = (name, value) => {
    let newState = { ...templateData };
    if (value) {
      newState[name + 'Error'] = null;
    }
    if (name === 'paymentCount') {
      if (value <= maxPaymentCount) {
        newState[name] = value;
      }
    } else {
      newState[name] = value;
    }
    setTemplateData(newState);
  };

  const handleCreateTemplate = async () => {
    let newState = { ...templateData };
    if (newState.name === '') {
      newState.nameError = 'Reason is required.';
    }
    if (newState.amount === '') {
      newState.amountError = 'Amount is required.';
    }
    if (newState.frequency === '') {
      newState.frequencyError = 'Frequency is required.';
    }
    if (newState.paymentCount === '') {
      newState.paymentCountError = 'No Of Payments is required.';
    }
    if (newState.debitDate === '') {
      newState.debitDateError = 'Debit Date is required.';
    }
    setTemplateData(newState);

    if (
      newState.name &&
      newState.amount &&
      newState.frequency &&
      newState.debitDate &&
      newState.paymentCount
    ) {
      await setTemplate({
        name: newState.name,
        reasonForPayment: newState.name,
        amount: parseInt(newState.amount),
        paymentCount: parseInt(newState.paymentCount),
        instantDebitAmount: parseInt(newState.instantDebitAmount),
        frequency: newState.frequency,
        debitDate: newState.debitDate,
      })
        .then((res) => {
          setTemplateData({
            name: '',
            nameError: null,
            amount: '',
            amountError: null,
            instantDebitAmount: '',
            frequency: '',
            frequencyError: null,
            debitDate: '',
            debitDateError: null,
            paymentCount: '',
            paymentCountError: null,
          });
          toast.success('Mandate created successfully!');
          handleNewTemplate();
        })
        .catch((err) => {
          toast.error('Something went wrong!');
        });
    }
  };

  return (
    <Modal open={isOpen} onClose={handleNewTemplate}>
      <Box sx={style}>
        <Stack
          direction={'row'}
          alignItems={'center'}
          justifyContent={'space-between'}
          px={2}
          pt={2}
        >
          <Typography variant="h6" fontWeight={600}>
            Create Payment Link
          </Typography>
          <IconButton onClick={handleNewTemplate}>
            <CloseIcon />
          </IconButton>
        </Stack>

        <Divider sx={{ my: 2 }} />
        <Box px={3} pb={2}>
          <Typography variant="subtitle1" fontWeight={500} mt={1}>
            Reason for payment
          </Typography>
          <TextField
            type="text"
            fullWidth
            value={templateData.name}
            placeholder="Reason for payment"
            sx={{ mt: 1 }}
            onChange={(e) => handleChange('name', e.target.value)}
            helperText={templateData.nameError}
            error={Boolean(templateData.nameError)}
          />
          <Typography variant="subtitle1" fontWeight={500} mt={1}>
            Amount
          </Typography>
          <TextField
            type="number"
            fullWidth
            value={templateData.amount}
            placeholder="Enter amount"
            sx={{ mt: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ color: '#A3A3A3' }}>₦</Typography>
                  <Typography sx={{ color: '#F0F0F0', ml: 1 }}>|</Typography>
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleChange('amount', e.target.value)}
            helperText={templateData.amountError}
            error={Boolean(templateData.amountError)}
          />
          <Typography
            variant="subtitle1"
            fontWeight={500}
            mt={1}
            sx={{ color: '#A3A3A3' }}
          >
            Amount to debit instantly (Optional)
          </Typography>
          <TextField
            type="number"
            fullWidth
            value={templateData.instantDebitAmount}
            disabled={true}
            placeholder="Enter amount"
            sx={{
              mt: 1,
              '& .MuiInputBase-input': {
                // Targeting the input directly
                color: '#A3A3A3', // Setting the text color
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Typography sx={{ color: '#A3A3A3' }}>₦</Typography>
                  <Typography sx={{ color: '#F0F0F0', ml: 1 }}>|</Typography>
                </InputAdornment>
              ),
            }}
            onChange={(e) => handleChange('instantDebitAmount', e.target.value)}
            helperText={templateData.amountError}
            error={Boolean(templateData.amountError)}
          />
          <Typography variant="subtitle1" fontWeight={500} mt={1}>
            Frequency
          </Typography>
          <Autocomplete
            id="frequency"
            fullWidth
            options={['Weekly', 'Monthly']}
            value={templateData.frequency}
            onChange={(e, newValue) => handleChange('frequency', newValue)}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Select frequency"
                sx={{ mt: 1 }}
                helperText={templateData.frequencyError}
                error={Boolean(templateData.frequencyError)}
              />
            )}
          />
          <Typography variant="subtitle1" fontWeight={500} mt={1}>
            Direct debit dates
          </Typography>
          <TextField
            type="date"
            fullWidth
            value={templateData.debitDate}
            placeholder="DD/MM/YYYY"
            sx={{ mt: 1 }}
            onChange={(e) => handleChange('debitDate', e.target.value)}
            helperText={templateData.debitDateError}
            error={Boolean(templateData.debitDateError)}
            InputProps={{
              inputProps: {
                min: formattedTomorrow, // Set min to today's date
                max: formattedTwelveMonthsFromNow, // Set max to end of the month
              },
            }}
            InputLabelProps={{
              shrink: true,
            }}
            label=""
          />

          <>
            <Typography variant="subtitle1" fontWeight={500} mt={1}>
              No of payments
            </Typography>
            <TextField
              type="number"
              fullWidth
              value={templateData.paymentCount}
              placeholder="No of times payments"
              sx={{ mt: 1 }}
              onChange={(e) => handleChange('paymentCount', e.target.value)}
              helperText={templateData.paymentCountError}
              error={Boolean(templateData.paymentCountError)}
              InputProps={{
                inputProps: {
                  min: 1,
                  max: maxPaymentCount,
                  step: 1,
                },
              }}
            />
          </>

          <Stack direction={'row'} justifyContent={'flex-end'} mt={3} gap={2}>
            <Button
              variant="outlined"
              sx={{ color: '#000', borderColor: '#DEDEDE' }}
              onClick={() => {
                handleNewTemplate();
                setTemplateData({
                  name: '',
                  nameError: null,
                  amount: '',
                  amountError: null,
                  instantDebitAmount: '',
                  frequency: '',
                  frequencyError: null,
                  debitDate: '',
                  debitDateError: null,
                  paymentCount: '',
                  paymentCountError: null,
                });
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ color: '#fff' }}
              onClick={handleCreateTemplate}
            >
              Create
            </Button>
          </Stack>
        </Box>
      </Box>
    </Modal>
  );
};

export default PaymentLinkModal;
