import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Container,
  Modal,
  Row,
  Spinner,
} from 'reactstrap';

// availity-reactstrap-validation
import {
  AvCheckbox,
  AvCheckboxGroup,
  AvField,
  AvForm,
} from 'availity-reactstrap-validation';

// action
import {
  apiError,
  registerUser,
  registerUserFailed,
} from '../../store/actions';

// Redux
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

// phone number
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';

import { generateGUID } from '../../helpers/commonFunctions';

// API
import { postJwtAdmin, eventLog } from '../../helpers/backend_helper';
// import images
import logoActive from '../../assets/images/logo-active.png';
const Register = (props) => {
  const [modal_standard, setmodal_standard] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState();
  const [phoneNumberError, setPhoneNumberError] = useState(null);

  const [userPhoneNumber, setUserPhoneNumber] = useState(null);
  const [userPhoneNumberError, setUserPhoneNumberError] = useState(null);
  const [directorPhoneNumber, setDirectorPhoneNumber] = useState();
  const [directorPhoneNumberError, setDirectorPhoneNumberError] =
    useState(null);
  const [users, setUsers] = useState([]);

  const [loading, setLoading] = useState(false);
  // for success or error
  const [registrationError, setRegisterError] = useState();
  const [registrationSuccess, setRegisterSuccess] = useState();
  // handleValidSubmit

   const validatePhoneNumber = (number) => {
     return isValidPhoneNumber(number);
  };
  const handleValidSubmit = (event, values) => {
    let newUsers = users.map((user) => {
      delete user.id;
      return { ...user };
    });

    // if (!validatePhoneNumber(phoneNumber)) {
    //   setPhoneNumberError('Please enter valid phone number');
    //   return;
    // }
    if (!validatePhoneNumber(directorPhoneNumber)) {
      setDirectorPhoneNumberError('Please enter valid phone number');
      return;
    }

    let usersList = [
      {
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        designation: 'Director',
        phoneNumber: directorPhoneNumber,
        password: values.password,
      },
    ];
    values['emailAddress'] = values.email;
    delete values.firstName;
    delete values.lastName;
    delete values.email;
    delete values.userType;
    delete values.password;
    
    values['phoneNumber'] = directorPhoneNumber;
    values['isVerified'] = false;
    values['isActive'] = false;
    
    values['users'] = [...usersList, ...newUsers];
    setLoading(true);
    // props.registerUser(values, props.history);
    postJwtAdmin(values)
      .then((response) => {
        setLoading(false);
        if (response.codes === 200) {
          const postObj = {
            journey: 'BUSINESS_REGISTRATION',
            eventName: 'BUSINESS_REGISTRATION.INITIATED',
            status: 'SUCCESS',
            uniqueIdentifier: values?.email,
            subIdentifier: '',
            data: {
              req: values,
              res: response.message,
            },
            source: 'BUSINESS_PARTNER',
          };
          eventLog(postObj);
          let id = response.message.match(/(\d+)/);
          localStorage.setItem('userId', id);
          setRegisterSuccess(response.message);
          setTimeout(() => {
            props.history.push('/registration-success');
          }, 1500);
        } else {
          const postObj = {
            journey: 'BUSINESS_REGISTRATION',
            eventName: 'BUSINESS_REGISTRATION.INITIATED',
            status: 'ERROR',
            uniqueIdentifier: values?.email,
            subIdentifier: '',
            data: {
              req: values,
              res: response.message,
            },
            source: 'BUSINESS_PARTNER',
          };
          eventLog(postObj);
          setRegisterError(response.message);
        }
      })
      .catch((err) => {});
  };
  const handleDirectorUser = (event, values) => {
    let userList = [...users];
    if (values.id === '') {
      values.id = generateGUID();
    }

    // values['phoneNumber'] = userPhoneNumber;

    // if (!validatePhoneNumber(userPhoneNumber)) {
    //   setUserPhoneNumberError('Please enter valid phone number');
    //   return;
    // }
    let index = userList.findIndex((user) => user.id === values.id);

    if (index !== -1) {
      userList[index] = values;
    } else {
      userList = [...userList, values];
    }
    setUsers([...userList]);
    setmodal_standard(false);
    setUserPhoneNumber(null);
  };

  // open modal
 {/* function tog_standard() {
    setmodal_standard(!modal_standard);
    setSecondaryModel();
    setUserPhoneNumber(null);
  }

  const removeSecondaryUser = (id) => {
    let index = users.findIndex((user) => user.id === id);
    if (index !== -1) {
      users.splice(index, 1);
    }
    setUsers([...users]);
  };
  const editSecondaryUser = (id) => {
    let editUser;
    let index = users.findIndex((user) => user.id === id);
    if (index !== -1) {
      editUser = users[index];
      setUserPhoneNumber(editUser.phoneNumber);
    }

    setSecondaryModel(editUser);
    setmodal_standard(true);
  };*/}

  useEffect(() => {
    props.apiError('');
    document.body.className = 'authentication-bg';
    // remove classname when component will unmount
    return function cleanup() {
      document.body.className = '';
    };
  });

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="align-items-center justify-content-center">
            <Col md={8} lg={6} xl={6}>
              <Card>
                <CardBody className="p-5">
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <Link to="/" className="my-2 d-block auth-logo">
                          <img
                            src={logoActive}
                            alt=""
                            height="50"
                            className="logo logo-dark"
                          />
                          {/* <img src={logolight} alt="" height="22" className="logo logo-light" /> */}
                        </Link>
                      </div>
                    </Col>
                  </Row>
                  <div className="text-center mt-2">
                    <h5 className="text-primary">Welcome to EDOC</h5>
                    <p className="text-muted">
                      Fill out the form to get started
                    </p>
                  </div>
                  <div className="text-heading mb-0">
                    <h6 className="mb-0">Company Information</h6>
                  </div>
                  <div className="p-2 mt-2">
                    <AvForm
                      className="form-horizontal"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v);
                      }}
                    >
                      <div className="mb-3">
                        <AvField
                          name="companyName"
                          label="Company Name"
                          type="text"
                          required
                          placeholder="Enter company name"
                          errorMessage="Please enter company name"
                        />
                      </div>
                      <h6>Personal Information</h6>
                      <Row>
                        <Col lg={6}>
                          <div className="mb-3">
                            <AvField
                              name="firstName"
                              label="First Name"
                              type="text"
                              required
                              placeholder="Enter first name"
                              errorMessage="Please enter first name"
                            />
                          </div>
                        </Col>
                        <Col lg={6}>
                          <div className="mb-3">
                            <AvField
                              name="lastName"
                              label="Last Name"
                              type="text"
                              required
                              placeholder="Enter last name"
                              errorMessage="Please enter last name"
                            />
                          </div>
                        </Col>
                      </Row>
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email Address"
                          type="email"
                          required
                          placeholder="Enter email address"
                          errorMessage="Please enter email"
                        />
                      </div>
                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          type="password"
                          required
                          placeholder="Enter Password"
                          errorMessage="Please enter password"
                        />
                      </div>
                      <div className="form-group mb-3">
                        <label>Phone Number</label>
                        <PhoneInput
                          placeholder="Enter phone number"
                          addInternationalOption={false}
                          defaultCountry={'NG'}
                          countries={['NG', 'GB']}
                          value={directorPhoneNumber}
                          onChange={(value) => {
                            setDirectorPhoneNumber(value);
                            setDirectorPhoneNumberError(null);
                          }}
                        />
                        {directorPhoneNumberError && (
                          <div className="invalid-feedback d-block">
                            {directorPhoneNumberError}
                          </div>
                        )}
                      </div>

                      {/*<div className="form-group mb-3">
                        <button
                          type="button"
                          onClick={() => {
                            tog_standard();
                          }}
                          className="btn btn-link border-0 text-dark px-0"
                          data-toggle="modal"
                          data-target="#myModal"
                        >
                          <i className="uil uil-plus"></i> Add Secondary
                          Directors
                        </button>
                        </div>
                      {users.length > 0 && (
                        <>
                          <Row className="mb-3">
                            <Col>
                              {users.map((user, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="d-flex align-items-center"
                                  >
                                    <div className="p-2">
                                      <i className="uil uil-user-circle h3"></i>
                                    </div>
                                    <div className="p-2 ">
                                      {user.firstName} {user.lastName}
                                    </div>
                                    <div className="p-2">
                                      <i
                                        className="uil uil-pen ml-2 h5 cursor-pointer px-2"
                                        onClick={() =>
                                          editSecondaryUser(user.id)
                                        }
                                      ></i>
                                      <i
                                        className="uil uil-trash-alt h5 text-danger cursor-pointer px-2"
                                        onClick={() =>
                                          removeSecondaryUser(user.id)
                                        }
                                      ></i>
                                    </div>
                                  </div>
                                );
                              })}
                            </Col>
                          </Row>
                        </>
                      )}*/}
                      <AvCheckboxGroup
                        inline
                        name="acceptedT&C"
                        required
                        errorMessage="Please accept term & condition"
                      >
                        <AvCheckbox
                          label={
                            <div>
                              By Creating an account you agree to accept our{' '}
                              <span
                                style={{ cursor: 'pointer' }}
                                className="fw-medium text-primary"
                                onClick={() =>
                                  window.open(
                                    'https://e-doconline.ng/documents/E-Doc+ng+Privacy+Policy.pdf'
                                  )
                                }
                              >
                                Privacy Policy
                              </span>{' '}
                              and{' '}
                              <span
                                style={{ cursor: 'pointer' }}
                                className="fw-medium text-primary"
                                onClick={() =>
                                  window.open(
                                    'https://e-doconline.ng/documents/E-Doc+Ng+Terms+%26+Conditions.pdf'
                                  )
                                }
                              >
                                Terms of Service
                              </span>
                            </div>
                          }
                          value={true}
                        />
                      </AvCheckboxGroup>
                      <div className="mt-3 text-end">
                        <button
                          className="btn btn-primary w-sm waves-effect waves-light"
                          type="submit"
                        >
                          {loading ? (
                            <span>
                              <Spinner
                                className="spinner-border-sm mr-5"
                                color="white"
                              />{' '}
                              Loading...
                            </span>
                          ) : (
                            'Register'
                          )}
                        </button>
                      </div>
                      <div className="mt-3">
                        {registrationSuccess && registrationSuccess ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}

                        {registrationError && registrationError ? (
                          <Alert color="danger">{registrationError}</Alert>
                        ) : null}
                      </div>

                      <div className="mt-4 text-center">
                        <p className="text-muted mb-0">
                          I already have an account ?{' '}
                          <Link to="/login" className="fw-medium text-primary">
                            {' '}
                            Login
                          </Link>
                        </p>
                      </div>
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/*<Modal
            isOpen={modal_standard}
            toggle={() => {
              tog_standard();
            }}
          >
            <AvForm
              className="form-horizontal"
              onValidSubmit={(e, v) => {
                handleDirectorUser(e, v);
              }}
              model={secondaryUserModel}
            >
              <div className="modal-header">
                <h6 className="modal-title mt-0" id="myModalLabel">
                  Add Information
                </h6>
                <button
                  type="button"
                  onClick={() => {
                    setmodal_standard(false);
                  }}
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <Row>
                  <Col lg={6}>
                    <div className="hidden">
                      <AvField
                        name="id"
                        label="id"
                        type="hidden"
                        className="hidden"
                      />
                      <AvField
                        name="userType"
                        label="First Name"
                        type="text"
                        value="S"
                        placeholder="Enter first name"
                        className="hidden"
                      />
                      <AvField
                        name="designation"
                        label="First Name"
                        type="text"
                        value="Director"
                        className="hidden"
                        placeholder="Enter first name"
                      />
                    </div>

                    <div className="mb-3">
                      <AvField
                        name="firstName"
                        label="First Name"
                        type="text"
                        placeholder="Enter first name"
                      />
                    </div>
                  </Col>
                  <Col lg={6}>
                    <div className="mb-3">
                      <AvField
                        name="lastName"
                        label="Last Name"
                        type="text"
                        placeholder="Enter last name"
                      />
                    </div>
                  </Col>
                </Row>
                <div className="mb-3">
                  <AvField
                    name="email"
                    label="Email Address"
                    type="email"
                    placeholder="Enter email address"
                  />
                </div>
                <div className="form-group mb-3">
                  <label>Phone Number</label>
                  <PhoneInput
                    placeholder="Enter phone number"
                    addInternationalOption={false}
                    defaultCountry={'NG'}
                    countries={['NG', 'GB']}
                    value={userPhoneNumber}
                    onChange={(value) => {
                      setUserPhoneNumber(value);
                      setUserPhoneNumberError(null);
                    }}
                  />
                  {userPhoneNumberError && (
                    <div className="invalid-feedback d-block">
                      {userPhoneNumberError}
                    </div>
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  onClick={() => {
                    tog_standard();
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  Close
                </button>
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"
                >
                  Save changes
                </button>
              </div>
            </AvForm>
                </Modal>*/}
        </Container>
      </div>
    </React.Fragment>
  );
};

Register.propTypes = {
  registerUser: PropTypes.func,
  registerUserFailed: PropTypes.func,
  registrationError: PropTypes.any,
  user: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { user, registrationError, loading } = state.Account;
  return { user, registrationError, loading };
};

export default connect(mapStatetoProps, {
  registerUser,
  apiError,
  registerUserFailed,
})(Register);
